import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import IStandardLogService from "./IStandardLogService";

@Injectable()
export class StandardLogService implements IStandardLogService {
  initializeAppInsights(userId?: string) {
    // do nothing
  }

  ensureUserIdIsSet(userId?: string) {
    // do nothing
  }

  logPageView(name?: string, url?: string) {
    console.log("Page View", { name, url });
  }

  logEvent(name: string, properties?: { [key: string]: unknown }) {
    console.log(`Event: ${name}`, { properties });
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: unknown },
  ) {
    console.log(`Metric: ${name}, average: ${average}`, { properties });
  }

  logException(exception: unknown) {
    console.error("Exception", exception);
  }

  logExceptionFromInterceptor(exception: HttpErrorResponse) {
    console.error("Exception from Interceptor", exception);
  }

  logTrace(message: string, properties?: { [key: string]: unknown }) {
    console.info(`Trace: ${message}`, properties);
  }
}
